import * as React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet";

import MainContent from "../components/MainContent";
import Layout from "../components/Layout"
import Heading from "@vfuk/core-heading";
import Paragraph from "@vfuk/core-paragraph";
import Button from "@vfuk/core-button"

//  markup
const NotFoundPage = () => {



  return (
    <Layout title="Page not found - Vodafone Business UC with RingCentral">

      <MainContent>

        <Helmet>
          <meta charSet="utf-8" />
          <title>404: Page Not Found - Vodafone Business UC with RingCentral</title>
        </Helmet>

        <Heading size={3} weight={2}>404: Page Not Found</Heading>
        <Paragraph>Unfortunately we couldn't find this page. It may have been deleted.</Paragraph>
        <Link to="/" style={{ textDecoration: "none" }}>
          <Button
            text='Go back home'
            appearance='primary'

          />
        </Link>

      </MainContent>

    </Layout>
  )
}

export default NotFoundPage
